// Generated by Framer (47124a4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, Link, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Material } from "https://framerusercontent.com/modules/6Ldpz1V0DkD45gXvi67I/PCgBX5d6MdQT7E7nhdXn/Material.js";
const MaterialFonts = getFonts(Material);
const MaterialControls = getPropertyControls(Material);

const enabledGestures = {AjkAgyjXn: {hover: true}};

const cycleOrder = ["AjkAgyjXn"];

const serializationHash = "framer-yxpb5"

const variantClassNames = {AjkAgyjXn: "framer-v-5plsy1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, iconName, id, link, tap, width, ...props}) => { return {...props, CgnhG_BTn: iconName ?? props.CgnhG_BTn ?? "MoreHoriz", MFXRrrEAk: tap ?? props.MFXRrrEAk, xFL5NxN9J: link ?? props.xFL5NxN9J} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;iconName?: string;link?: string;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, CgnhG_BTn, xFL5NxN9J, MFXRrrEAk, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "AjkAgyjXn", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap24a3vz = activeVariantCallback(async (...args) => {
if (MFXRrrEAk) {
const res = await MFXRrrEAk(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-yxpb5", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={xFL5NxN9J} openInNewTab={false} smoothScroll><motion.a {...restProps} className={`${cx("framer-5plsy1", className)} framer-168sje`} data-framer-name={"Button"} data-highlight data-reset={"button"} layoutDependency={layoutDependency} layoutId={"AjkAgyjXn"} onTap={onTap24a3vz} ref={ref ?? ref1} style={{backgroundColor: "var(--token-0c5d6188-2305-449f-bacc-6b42117b54dc, rgb(245, 245, 250))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, opacity: 1, ...style}} variants={{"AjkAgyjXn-hover": {backgroundColor: "var(--token-0c5d6188-2305-449f-bacc-6b42117b54dc, rgb(248, 242, 237))", opacity: 0.8}}} {...addPropertyOverrides({"AjkAgyjXn-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-bshyo5-container"} layoutDependency={layoutDependency} layoutId={"byDs5_OK6-container"}><Material color={"var(--token-e065af15-a162-4041-821a-20e240d9ad3d, rgb(0, 0, 0)) /* {\"name\":\"#000000\"} */"} height={"100%"} iconSearch={"Home"} iconSelection={CgnhG_BTn} iconStyle15={"Filled"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"byDs5_OK6"} layoutId={"byDs5_OK6"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"} {...addPropertyOverrides({"AjkAgyjXn-hover": {color: "rgba(0, 0, 0, 0.7)"}}, baseVariant, gestureVariant)}/></motion.div></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-yxpb5 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-yxpb5 .framer-168sje { display: block; }", ".framer-yxpb5 .framer-5plsy1 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; padding: 13px 13px 13px 13px; position: relative; text-decoration: none; width: min-content; }", ".framer-yxpb5 .framer-bshyo5-container { flex: none; height: 20px; position: relative; width: 20px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-yxpb5 .framer-5plsy1 { gap: 0px; } .framer-yxpb5 .framer-5plsy1 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-yxpb5 .framer-5plsy1 > :first-child { margin-left: 0px; } .framer-yxpb5 .framer-5plsy1 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 46
 * @framerIntrinsicWidth 46
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"jjrL71IMZ":{"layout":["auto","auto"]}}}
 * @framerVariables {"CgnhG_BTn":"iconName","xFL5NxN9J":"link","MFXRrrEAk":"tap"}
 * @framerImmutableVariables true
 */
const FramerDHwYUhCvM: React.ComponentType<Props> = withCSS(Component, css, "framer-yxpb5") as typeof Component;
export default FramerDHwYUhCvM;

FramerDHwYUhCvM.displayName = "Button / Icon - Resting";

FramerDHwYUhCvM.defaultProps = {height: 46, width: 46};

addPropertyControls(FramerDHwYUhCvM, {CgnhG_BTn: MaterialControls?.["iconSelection"] && {...MaterialControls["iconSelection"], defaultValue: "MoreHoriz", hidden: undefined, title: "Icon Name"}, xFL5NxN9J: {title: "Link", type: ControlType.Link}, MFXRrrEAk: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerDHwYUhCvM, [...MaterialFonts])